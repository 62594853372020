@use "sass:map";
@use "@angular/material" as mat;

@import "./variables";
@import "./mixins";

@mixin buttons-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  .shop-mat-primary {
    background-color: mat.get-color-from-palette($primary, 400) !important;
    &:hover {
      background-color: mat.get-color-from-palette($primary, 500) !important;
    }
  }
  .shop-mat-stroked {
    color: mat.get-color-from-palette($primary, 800) !important;
    border-color: mat.get-color-from-palette($primary, 800) !important;
    &:hover {
      color: mat.get-color-from-palette($primary, 900) !important;
      border-color: mat.get-color-from-palette($primary, 900) !important;
    }
  }
  .shop-mat-save {
    background-color: mat.get-color-from-palette($primary, 400) !important;
    &:hover {
      background-color: mat.get-color-from-palette($primary, 500) !important;
    }
  }
}

.shop-mat-stroked {
  padding: 0 2rem;
  text-transform: uppercase;
  font-size: 15px;
  @include mediaMaxWidth($resXS) {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-save {
  transition: all 0.1s ease;
  padding: 0 2rem;
  // background-color: #007791;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
  // &:hover {
  //   background-color: #008cab;
  // }
  @include mediaMaxWidth($resXS) {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-primary {
  padding: 0 2rem !important;
  // background-color: $standard-dark-blue-color !important;
  color: var(--white) !important;
  text-transform: uppercase;
  // &:hover {
  //   background-color: $standard-dark-blue-hover-color !important;
  // }
  @include mediaMaxWidth($resXS) {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-danger {
  padding: 0 2rem;
  background-color: $red-color;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
  &:hover {
    background-color: darken(#f44336, 10%);
  }
  @include mediaMaxWidth($resXS) {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-cancel {
  background-color: var(--white);
  color: var(--black);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  font-size: 15px;
  &:hover {
    background-color: var(--white);
  }
  @include mediaMaxWidth($resXS) {
    width: 100%;
    justify-content: center;
  }
}
