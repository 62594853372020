@use "sass:map";
@use "@angular/material" as mat;

@import "../../../scss/theme";

@mixin component-navbar-header-two-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");
    $custom-colors: map-get($theme, custom-colors);

    .navbar-top-cart-wrapper {
        background-color: map-get($custom-colors, navbar-cart-wrapper);
    }
    #cart-ammount {
        padding-left: 0.5rem;
        color: mat.get-color-from-palette($primary, 50);
    }
    .navbar-top-cart-wrapper a svg path {
        fill: mat.get-color-from-palette($primary, 50);
    }
    .shop-header--language {
        &-border {
            background: mat.get-color-from-palette($primary, 500);
        }
    }
}
