@use "sass:map";
@use "@angular/material" as mat;

@mixin classic-effect-after-before-theme($theme: null) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");
    .title1 h4 {
        @if ($theme) {
            color: mat.get-color-from-palette($primary, 700);
        } @else {
            color: #ff4c3b !important;
        }
    }
    .blog-details {
        h4 {
            color: mat.get-color-from-palette($primary, 700);
        }
        p {
            &:hover {
                color: mat.get-color-from-palette($primary, 700) !important;
            }
        }
    }
}

@mixin classic-effect-after-before() {
    width: 25%;
    position: absolute;
    content: "";
    opacity: 0;
    -webkit-transition: all 0.3s steps(4);
    transition: all 0.3s steps(4);
    z-index: 1;
    bottom: 100%;
    top: 0;
    background: #f1f5f4;

    @content;
}
