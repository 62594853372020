@import "../../../../../scss/theme";
@import "./from-the-blog-theme";

.blog-bg {
  background-color: #f1f5f4;
}

.from-the-blog {
  max-width: var(--shop-max-width);
  margin: 2.5rem auto 0rem auto;
  width: 100%;
  .from-the-blog--title {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 22px;
    line-height: 1.2;
    h2,
    h3 {
      margin: 0;
    }
    h3 {
      font-size: 18px;
    }
  }
  .slick-slide {
    padding-left: 1rem;
    padding-right: 1rem;
    img {
      border-radius: 6px;
    }
  }
  @include mediaMaxWidth($resM) {
    .from-the-blog--title {
      font-size: 22px;
      h3 {
        font-size: 16px;
      }
    }
    .slick-slide {
      padding: 0;
    }
  }
}

.classic-effect {
  position: relative;
  overflow: hidden;
  width: 438px;
  height: 292px;
  &:after {
    @include classic-effect-after-before() {
      left: 25%;
      -webkit-transition-delay: 0.025s;
      transition-delay: 0.025s;
    }
  }
  &:before {
    @include classic-effect-after-before() {
      left: 0;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
  span:after {
    @include classic-effect-after-before() {
      left: 75%;
      -webkit-transition-delay: 0.15s;
      transition-delay: 0.15s;
    }
  }
  span:before {
    @include classic-effect-after-before() {
      left: 50%;
      -webkit-transition-delay: 0.05s;
      transition-delay: 0.05s;
    }
  }
  &:hover {
    &:before,
    &:after {
      bottom: 0;
      opacity: 0.5;
    }
    span {
      &:before,
      &:after {
        bottom: 0;
        opacity: 0.5;
      }
    }
  }
}

.basic-effect {
  position: relative;
  overflow: hidden;
  img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  &:after {
    position: absolute;
    content: "";
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(1, 239, 252, 0.8)),
      color-stop(99%, rgba(72, 95, 242, 0.8))
    );
    background: linear-gradient(-180deg, rgba(1, 239, 252, 0.8) 0%, rgba(72, 95, 242, 0.8) 99%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  &:hover {
    img {
      -webkit-transform: scale(1.2) rotate(5deg);
      transform: scale(1.2) rotate(5deg);
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    &:after {
      opacity: 1;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
}

.from-the-blog--details {
  h4 {
    margin-top: 1rem;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15.5px;
    color: #797878;
  }
  p {
    color: var(--black);
    font-size: 18.5px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.03em;
    margin-top: 10px;
    margin-bottom: 0;
    transition: all 0.5s ease;
  }
  h6 {
    line-height: 1;
    letter-spacing: 0.1em;
    margin-bottom: 0;
    padding: 5px 0;
  }
}

.flash-hover a:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
