@use "sass:map";
@use "@angular/material" as mat;

@import "../../../../scss/theme";

@mixin sidebar-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");

    .active {
        color: mat.get-color-from-palette($primary, 800) !important;
    }
    .active-sublink {
        color: mat.get-color-from-palette($primary, 800) !important;
        font-weight: 500;
    }
    .submenu-category-item:hover {
        color: mat.get-color-from-palette($primary, 800) !important;
    }
    .sidebar-header::after {
        content: " ";
        display: block;
        height: 1px;
        background-color: mat.get-color-from-palette($primary, 500);
        width: 100%;
        margin: 0 auto;
        margin-top: 10px;
    }
}
