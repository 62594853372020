// http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/

$resXXXL: 1600px;
$resXXL: 1280px;
$resXL: 1199px;
$resL: 991px;
$resM: 767px;
$resS: 660px;
$resXS: 575px;
$resXXS: 435px;

$assets-root: "/assets";
$images-root: "/img/";
$fonts-root: "/fonts/";
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;
$font-bolder: 700;
$font-ultraBold: 900;

$fancy-dark-gray-color: #546b81;
$default-gray-color: #dddddd;
$default-gray-hover-color: #c6c6c6;
$dark-blue-color: #505b6f;
$dark-blue-hover-color: #5d6d7e;
$yellow-color: #fcca4b;
$yellow-hover-color: #eac15d;
$yellow-border-color: #af8621;
$orange-color: #e67e22;
$orange-hover-dark-color: #cf6d17;
$orange-hover-light-color: #eb984e;
$light-gray-color: #f2f2f2;
$graphit-color: #555;
$graphit-dva-color: #494f57;
$standard-blue-color: cornflowerblue;
$light-blue-color: #10bbd5;
$light-blue-hover-color: #65d1e2;
$green-color: #008a00;
$standard-dark-blue-color: #007791;
$standard-dark-blue-hover-color: #008cab;
$standard-green-color: rgb(129, 186, 0);
$github-color: #6b6b6b;
$red-color: #f44336;
