@use "sass:map";
@use "@angular/material" as mat;

@import "./app/scss/theme";

:root {
  --white: #ffffff;
  --black: #212428;
  --shop-max-width: 1440px;
  --font-frank-ruhl-libre: "Frank Ruhl Libre", sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-frank-ruhl-libre);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum=1";
  -ms-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  -o-font-feature-settings: "lnum";
}

html {
  background-color: var(--white);
  color: var(--black);
}

.cc-btn:hover,
.cc-btn:focus {
  background-color: #f5efff !important;
}

.help-block {
  font-size: 14px;
  color: #842029;
}

.shop-alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

a {
  color: #007790;
  font-style: normal;
  text-decoration: none;
}

ol {
  list-style-type: circle;
}

ul {
  list-style-type: none;
}

.navbar {
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0.5rem 0rem;
}

.pdp-small-header {
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: #333;
  font-family: var(--font-frank-ruhl-libre);
}

.content-front {
  background-color: var(--white);
  box-shadow: 0px 1px 12px 0 rgba(34, 36, 38, 0.15);
}

.img-thumbnail {
  border-radius: 0px;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.pdp-green-font {
  color: #008a00;
  font-weight: 700;
  font-size: 14px;
}

.pdp-danger-font {
  color: #e67e22;
  font-weight: 700;
  font-size: 14px;
}

.pdp-margin-top {
  margin: 10px 0 0 0;
}

.margin-left-10 {
  margin-left: 10px;
}

.a-primary {
  color: var(--black);
}

.btn:active:focus {
  border-color: $graphit-dva-color;
}

.form-control {
  font-size: 15px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 2px;
}

.modal-close-text {
  font-size: 15px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  color: #007790;
  opacity: 1;
}

.close {
  color: gray;
  opacity: 1;
  text-shadow: none;

  &:hover {
    color: var(--black);
  }
}

.modal {
  margin-left: auto;
  margin-right: auto;
}

.modal-content {
  border-radius: 2px;
}

.modal-header {
  padding: 5px 5px 5px 5px;
}

.pdp-clickable {
  cursor: pointer;
  color: #4183c4;
}

.pdp-clickable.logo {
  padding: 0px;
}

.pdp-margin-top-big {
  margin-top: 20px;
}

.pdp-margin-top-medium {
  margin-top: 8px;
}

.pdp-margin-bottom-medium {
  margin-bottom: 8px;
}

.pdp-bordered {
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 20px;
}

.pdp-bordered-top {
  border-top: 1px solid #ccc;
  box-shadow: 0 -5px 5px -5px rgba(34, 36, 38, 0.15);
  padding: 20px;
}

.row-eq-height {
  display: flex;
  display: -webkit-flex;
}

.pdp-red {
  color: #e3141a;
}

.has-danger > input,
.has-error > input,
.has-success > input,
.has-warning > input {
  background-repeat: no-repeat;
  background-position: center right 0.2rem;
  background-size: 2rem 2rem !important;
}

.has-error > input {
  color: #f3eeea;
  border: 1px solid #f3eeea;
  background: url("data:image/svg+xml;charset=UTF-8, <svg width='40' height='40' fill='%23dc143c' id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400'><title>Artboard 202</title><path d='M287.08,278.57,213.49,205,287,131.5a6,6,0,0,0-8.49-8.49L205,196.49,131.52,123A6,6,0,0,0,123,131.5L196.51,205l-73.6,73.6a6,6,0,1,0,8.49,8.49l73.6-73.6,73.6,73.6a6,6,0,0,0,8.49-8.49Z'/></svg>")
    no-repeat center right 0.2rem;
}

.has-error > select {
  color: #f3eeea;
  border: 1px solid #f3eeea;
}

.has-error {
  color: #f3eeea !important;
}

.has-success > input {
  background: url("data:image/svg+xml;charset=UTF-8, <svg width='40' height='40' fill='%23008a00' id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400'><title>Artboard 104 copy 18</title><path d='M316.16,99.48a6,6,0,0,0-8.44.83L172,265.45,97,171.93a6,6,0,1,0-9.36,7.5L166,277.19a27,27,0,0,1,2.18,2.32,6,6,0,0,0,8.44-.83L317,107.93A6,6,0,0,0,316.16,99.48Z'/></svg>")
    no-repeat center right 0.2rem;
  border: 1px solid #008a00 !important;
}

.box-heading {
  font-size: 18px;
  padding: 0;
  line-height: 1.5;
  color: var(--black);
  margin-bottom: 0;
}

.width-25 {
  width: 25px;
}

.width-50 {
  width: 50px;
}

.width-85 {
  width: 85px;
}

.width-140 {
  width: 140px;
}

.width-160 {
  width: 160px;
}

.word-no-split {
  white-space: nowrap !important;
}

/**used in checkout for hiding incomplete shipping option http://www.talkingdotnet.com/dont-use-hidden-attribute-angularjs-2/*/
[hidden] {
  display: none !important;
}

.pdp-border-left {
  border-left: thin solid rgba(34, 36, 38, 0.15);
}

.pdp-border-right {
  border-right: thin solid rgba(34, 36, 38, 0.15);
}

.image-center {
  display: block;
  margin: 0 auto;
}

.icon-75 {
  width: 75px;
}

.icon-20 {
  width: 20px;
}

.icon-15 {
  width: 15px;
}

.parent-modal-class {
  max-width: 100% !important;
  overflow-y: auto;
}

////// to remove arrows on input type='number'
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.navbar-brand {
  margin-right: 0px;
  margin-left: 0px;
}

.footer__info__content {
  display: grid;
  grid-gap: 1rem;

  @include mediaMinWidth($resXS + 1) {
    grid-template-columns: 65% 1fr;
  }

  @include mediaMaxWidth($resL) {
    margin-bottom: 1rem;
  }
}

.footer-one-left-sextion-a + .footer-one-left-sextion-a {
  margin-top: 0.3rem;
}

.popular-tags a {
  display: inline-block;
  line-height: 24px;
  padding: 6px 22px;
  margin: 0px 8px 10px 0px;
  border: 1px solid #e0e0e0;
  color: #696969;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  background: rgba(0, 0, 0, 0.05);
}

.footer__info__description {
  margin-top: 0;
  margin-bottom: 0.5rem;
  span {
    margin-top: 1rem;
    display: block;
  }
}

@include mediaMaxWidth($resXS) {
  .navbar {
    padding: 0px !important;
  }
}

@mixin global-styles-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  $custom-colors: map-get($theme, custom-colors);

  .user-profile {
    &.open {
      .user-profile--icon {
        background-color: mat.get-color-from-palette($primary, 400);
      }
    }
    .user-profile--icon {
      &:hover {
        background-color: mat.get-color-from-palette($primary, 400);
      }
    }
    .user-profile--details {
      a {
        color: var(--black);
        &:hover {
          color: mat.get-color-from-palette($primary, 600);
        }
      }
    }
    ul {
      a {
        color: var(--black);
        &:hover {
          color: mat.get-color-from-palette($primary, 600);
        }
      }
    }
  }

  .mini-cart {
    &.open {
      .mini-cart--icon {
        background-color: mat.get-color-from-palette($primary, 400);
        .mini-cart--counter {
          color: mat.get-color-from-palette($primary, 400) !important;
          border: 2px solid mat.get-color-from-palette($primary, 400);
        }
      }
    }
    .mini-cart--icon {
      &:hover {
        background-color: mat.get-color-from-palette($primary, 400);
        .mini-cart--counter {
          color: mat.get-color-from-palette($primary, 400) !important;
          border: 2px solid mat.get-color-from-palette($primary, 400);
        }
      }
      .mini-cart--counter {
        background-color: mat.get-color-from-palette($primary, 400);
      }
    }
  }

  .breadcrumb-wrapper--item,
  .register-page--form-account,
  .login-register--form-forgot {
    a {
      color: mat.get-color-from-palette($primary, 900) !important;

      &:hover {
        color: mat.get-color-from-palette($primary, 800) !important;
      }
    }
  }

  .home-text-wrapper,
  .customer-support--item,
  .footer-one-left-sextion-a,
  .footer__info__description {
    a {
      color: var(--black);

      &:hover {
        color: mat.get-color-from-palette($primary, 900);
      }
    }
  }

  .already-have-shipping-address-delete,
  .new-address-box-delete {
    button {
      color: mat.get-color-from-palette($primary, 800) !important;

      &:hover {
        color: mat.get-color-from-palette($primary, 900) !important;
      }
    }
  }

  .forgot-password--form-buttons {
    a:hover {
      color: mat.get-color-from-palette($primary, 800) !important;
    }
  }

  .category-thumb:hover .refine-search__name {
    color: mat.get-color-from-palette($primary, 400);
  }

  .account-dashboard--sidebar {
    a:hover {
      color: mat.get-color-from-palette($primary, 800);
    }

    a.active {
      color: mat.get-color-from-palette($primary, 900) !important;
    }
  }

  .home-text-inner {
    a {
      color: mat.get-color-from-palette($primary, 800) !important;

      &:hover {
        color: mat.get-color-from-palette($primary, 900) !important;
      }
    }
  }

  .address-book-dashboard--conten {
    .add-new-address:hover {
      color: mat.get-color-from-palette($primary, 800);
    }
  }

  .form-control:focus {
    outline: none;
    box-shadow: none !important;
    border: 1px solid mat.get-color-from-palette($primary, 400) !important;
  }

  a:hover {
    color: map-get($custom-colors, link-hover-color);
    transition: background-color 0.5s;
  }

  #search-param {
    color: mat.get-color-from-palette($primary, 500) !important;
    font-weight: 450;
    font-size: 15px;
  }

  .modal-close-text:hover {
    color: mat.get-color-from-palette($primary, 500);
  }

  .footer-bottom {
    padding: 1.2rem 0rem;
    background: #ececec;

    .footer-bottom--item {
      padding: 0rem 1rem;
      margin: 0 auto;
      max-width: var(--shop-max-width);

      .footer-bottom--item-divider {
        height: 15px;
        width: 2px;
        display: inline-block;
        background: mat.get-color-from-palette($primary, 500);
        margin: -2px 5px;
      }

      a {
        color: var(--black);
        font-weight: 500;
        margin-left: 5px;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  .active {
    font-weight: 700;
    color: var(--black);
  }

  .category-title {
    &::after {
      background-color: mat.get-color-from-palette($primary, 500);
    }
  }

  .header {
    border-bottom: thin solid mat.get-color-from-palette($primary, 500);
    padding-top: 5px;
  }
}

.page-item.active .page-link {
  border: 1px solid #007790;
  background-color: #007790;
}

.customer-support--item-title {
  color: var(--black);
  background: #fcca4b;
  font-size: 19px;
  padding: 5px 10px;
}

.customer-support--item-subtitle {
  color: var(--black);
  background: #f2f2f2;
  font-size: 19px;
  padding: 5px 10px;
}

.customer-suppor-grid-title {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  gap: 2px;

  div:nth-child(-n + 3) {
    background: #fcca4b !important;
  }

  div {
    background: #f2f2f2;
    padding: 5px;
  }

  @include mediaMaxWidth($resXS) {
    font-size: 12px;
  }
}

.category-title {
  position: relative;
  font-size: 28px;
  color: var(--black);
  margin-bottom: 2rem;
  &::after {
    content: "";
    width: 100px;
    height: 3px;
    display: block;
    margin-top: 0.8rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  @include mediaMaxWidth($resXS) {
    font-size: 24px;
  }
}

.mat-tooltip {
  position: relative;
  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #0000008c;
  }
  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;
    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}

.hover-animation {
  &::after {
    display: block;
    content: "";
    border-bottom: solid 2px #007790;
    border-radius: 5px;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}

.mat-error {
  color: #eb984e !important;
}

.shine-image figure {
  position: relative;
  overflow: hidden;
}

.shine-image figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  cursor: pointer;
}

.shine-image figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

.example-full-width .mat-form-field-infix {
  width: 100% !important;
}

.example-full-width {
  width: 100% !important;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
