@use "sass:map";
@use "@angular/material" as mat;

@mixin component-checkout-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");
    $accent: map.get($color-config, "accent");
    $warn: map.get($color-config, "warn");

    .wrapper {
        color: rgba(0, 0, 0, 0.54);
    }
}
