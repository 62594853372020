@import "../../../scss/variables";

@mixin component-footer-two-theme($theme) {
    $custom-colors: map-get($theme, custom-colors);

    .footer-wrapper-parent {
        background-color: map-get($custom-colors, footer-background-color);
    }
    .active-navbar {
        color: var(--black);
        font-weight: 500;
    }
}
