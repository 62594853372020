@use "sass:map";
@use "@angular/material" as mat;

@import "../../../scss/variables";

@mixin component-footer-one-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");

    .active-navbar {
        color: var(--black);
        font-weight: 500;
    }

    .main-footer__list__item,
    .footer__list__item {
        a:hover {
            color: mat.get-color-from-palette($primary, 800) !important;
        }
    }
}
