.fc-widget-normal .hotline-launcher.h-open,
.fc-widget-normal.expanded-modal .hotline-launcher {
  width: 330px !important;
}

::ng-deep {
  .hotline-launcher.h-open {
    width: 330px !important;
  }
}
