@use "sass:map";
@use "@angular/material" as mat;

@import "../../../scss/theme";

@mixin component-header-three-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  $custom-colors: map-get($theme, custom-colors);

  .header-three-cmpt--top {
    background: mat.get-color-from-palette($primary, 500);
  }

  .hover-animation {
    @include mediaMinWidth(1024px) {
      &:after {
        background-color: mat.get-color-from-palette($primary, 500);
      }
    }

    @include mediaMaxWidth(1023px) {
      &:hover {
        background: mat.get-color-from-palette($primary, 300) !important;
        color: var(--white) !important;
      }
    }
  }

  .navbar-dropdown--mega-menu {
    .navbar-dropdown--mega-menu-s3 {
      a {
        border-bottom: 2px solid mat.get-color-from-palette($primary, 500);
      }
    }
  }

  .hoverable-images {
    &:after {
      background-color: mat.get-color-from-palette($primary, 500);
    }
  }
}
