@mixin mediaMinWidth($width) {
  @media (min-width: $width) {
    @content;
  }
}
  
@mixin mediaMaxWidth($width) {
  @media (max-width: $width) {
    @content;
  }
}