@use "sass:map";
@use "@angular/material" as mat;

@import "../../../scss/variables";

@mixin component-search-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");
    $custom-colors: map-get($theme, custom-colors);

    .search-button {
        background-color: map-get($custom-colors, search-button);
        height: 100%;
        padding: 0px 11px;
        display: flex;
        border-style: none;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: map-get($custom-colors, search-button-hover);
        }
        svg path {
            fill: map-get($custom-colors, search-icon);
        }
    }
    .input-search--products--item {
        color: var(--black);
        &:hover {
            color: mat.get-color-from-palette($primary, 800) !important;
        }
    }
    .icon-search {
        color: var(--white);
    }
}
