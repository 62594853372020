@use "sass:map";
@use "@angular/material" as mat;

@mixin labels-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, "primary");
    .lable3 {
        background-color: mat.get-color-from-palette($primary, 700);
        color: mat.get-color-from-palette($primary, 50);
    }
}
