@import "../../../../../scss/variables";

@mixin product-list-header-theme($theme) {
  // $primary: map-get($theme, primary);
  // $custom-colors: map-get($theme, custom-colors);
  // .active {
  //   > a {
  //     background-color: mat-color($primary, 500);
  //     color: var(--white);
  //     transition: none;
  //   }
  // }

  // .page-item.active .page-link {
  //   border: 1px solid mat-color($primary, 500);
  //   background-color: mat-color($primary, 500);
  // }

  #category-name {
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
  }
}
