@charset "utf-8";

@use "@angular/material" as mat;
@use "./new-theme-base" as newTheme;

@include mat.core();

// read this if you have any questions about the theme: https://angular-material.dev/articles/angular-material-theming-system-complete-guide
@import "./theme-base";

@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&display=swap");

$platform-light-blue: (
  50: #e0eff2,
  100: #b3d6de,
  200: #80bbc8,
  300: #4da0b2,
  400: #268ba2,
  500: #007791,
  600: #006f89,
  700: #00647e,
  800: #005a74,
  900: #004762,
  A100: #92dbff,
  A200: #5fcaff,
  A400: #2cbaff,
  A700: #13b1ff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$primary: mat.define-palette($platform-light-blue);
$accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette, A200, A100, A400);

// $primary: mat-palette($platform-light-blue);
// $accent: mat-palette($mat-pink, A200, A100, A400);
// $warn: mat-palette($mat-orange, A200, A100, A400);

// // Change mat-light-theme into mat-dark-theme and your theme will use the darker values of your palette.
// $theme: mat-light-theme($primary, $accent, $warn);

// $custom-typography: mat-typography-config(
//   $font-family: var(--font-frank-ruhl-libre),
//   $headline: mat-typography-level(26px, 40px, 700),
//   $title: mat-typography-level(24px, 40px, 600),
//   $body-1: mat-typography-level(16px, 24px, 500),
// );

// // Custom colors

// @include theming($theme);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      //  // Define custom colors for success and info
      //  success: mat.define-palette(mat.$green-palette),
      //  info: mat.define-palette(mat.$blue-palette),
    ),
    // typography: $custom-typography,
    density: 0
  )
);

$custom-colors: (
  footer-background-color: #f2f2f2,
  search-icon: mat.get-color-from-palette($primary, 50),
  search-button: mat.get-color-from-palette($primary, 500),
  search-button-hover: mat.get-color-from-palette($primary, 700),
  navbar-cart-wrapper: mat.get-color-from-palette($primary, 50),
  link-hover-color: #000000
);

$theme: map-merge(
  $theme,
  (
    custom-colors: $custom-colors
  )
);

@include mat.all-component-themes($theme);
@include newTheme.theme($theme);

/**
* BULMA CSS
*/
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$navbar-color-variable: white;
$navbar-color-hover-variable: #589294;
$navbar-item-color-graphit: #555;

// Update Bulma's global variables
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$family-sans-serif: "Frank Ruhl Libre", serif;
$navbar-background-color: white;
$navbar-item-hover-color: white;
$navbar-item-color: $navbar-item-color-graphit;
$navbar-item-hover-background-color: #007791;
$navbar-dropdown-background-color: #007791;
.navbar-end {
  background-color: white;
}

.form-sm {
  @include mat.form-field-density(-3);
}
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/form/_all.sass";
@import "node_modules/bulma/sass/components/navbar.sass";
@import "node_modules/bulma/sass/layout/section.sass";
