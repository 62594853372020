@use "sass:map";
@use "@angular/material" as mat;

@import "../../../scss/theme";

@mixin component-header-one-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  .main-nav__link:hover {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .subcat-item {
    a:hover {
      color: mat.get-color-from-palette($primary, 800) !important;
    }
  }

  .active-navbar {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .pdp-header-one-top-wrapper--grid-content {
    a {
      color: var(--black);
      &:hover {
        color: mat.get-color-from-palette($primary, 800);
      }
    }
  }

  .pdp-header-one-top-wrapper--grid-cart {
    background-color: mat.get-color-from-palette($primary, 800);
  }
}
